import * as React from "react"
import { createGlobalStyle } from 'styled-components';
import { GlobalConfig, validateConfig } from "../../locust/composables/Layout";
import { PreloadScreen } from "../../locust/composables/PreloadScreen";
import {getRandomString} from '../../locust/composables/Shared'
import { HeaderSpacer } from "../../locust/components/Header";
import { getTestItemCardBasic } from "../../client-ui/checkout/TestComponents";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase-config";
import { getShoppingCart, setShoppingCart } from "../../service/shopping-cart";
import { setCheckoutData, setCheckoutProgress } from "../../service/checkout-progress";
import { portalAuthStorage00 } from "../../../firebase/firebase-portal-storage-00";
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings";




export const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
}
html, body {
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
  }
  body {
    position: relative;
    width: 100%;
    background-image: radial-gradient(farthest-corner at max(50%,150px) 50%,
      #fff 0%, #fff 20%, #fff 70%,#dae5ea 100%);
      height : 100vh
  }
  img{
    max-width:100%;
 }  
`;


const Page = () => {
  const config : GlobalConfig = validateConfig()
  const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
  const menuBreakpoint : number = 600 
  const [toggleMenu, setToggleMenu] = React.useState(false)
  const [cart, setCart] = React.useState({})
  
  React.useEffect(()=>{
    setCart(getShoppingCart())
    //alert(JSON.stringify(Object.keys(getShoppingCart())))
    //setCart(getShoppingCart())
    


  },[])

  return (
      <>
        <PreloadScreen>
            <GlobalStyle />
            <HeaderSpacer/>
            <button onClick={()=>{
              const updatedCart = {
                ...getShoppingCart(), 
                [getRandomString()] : getTestItemCardBasic() 
              }
              setShoppingCart(updatedCart)
              setCart(getShoppingCart())
              
            }}style={{ margin: 20, height : 40, width : 140 }}>add to cart</button>
            
            <button 
                style={{ margin: 20, height : 40, width : 140 }}
                onClick={()=>{
                    Object.keys(getShoppingCart()).forEach(( keyValue : string )=>{
                
                      if( ENABLE_ERROR_LOGGING ) console.log(`"${keyValue}":` + JSON.stringify(getShoppingCart()[keyValue]))
                    })
             
              
                }}
                
            >
              show cart
            </button>


            
            <button 
                style={{ margin: 20, height : 40, width : 140 }}
                onClick={()=>{ setShoppingCart({}), setCart(getShoppingCart())  }}
            >
              Clear Cart
            </button>


            <button 
                style={{ margin: 20, height : 40, width : 140 }}
                onClick={async()=>{
                  setShoppingCart({})
                  setCart(getShoppingCart()) 
                  setCheckoutData({})
                  setCheckoutProgress({}) 
                  await signOut(auth)
                  await signOut(portalAuthStorage00)
                  
                  
                }}
            >
              Clear All
            </button>

          </PreloadScreen>
          
      </>
  )
}
export default Page
